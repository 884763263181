<template>
  <div class="pt-16">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full h-75vh md:h-50vh lg:h-75vh py-12 px-4 md:px-0 flex items-center"
      >
        <div class="w-full bg-softGrey80">
          <p
            class="md:w-768 lg:w-1024 m-auto p-4 text-xl text-pulpBlue text-center font-semibold"
          >
            <b
              >Protect your training budget, spend on English learning with
              results now!​</b
            >
            <br />
            neoAssess is the tool you need to stay on budget with a quick,
            secure, and low-cost way to manage thousands of staff and job
            candidates globally. ​
          </p>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full text-textBlack text-justify">
            <h3 class="text-2xl mb-2 text-primary font-semibold">
              Filter any number of job candidates that require English fluency
              before investing in costly interviews
            </h3>
            <p>
              neoAssess will provide you with an evaluated pool of candidates
              that meet your English-level requirements. The process is simple.
              You bulk-upload the candidate list on neo’s Dashbaord, and we take
              care of the rest. If you have the perfect candidate, we'll raise
              their English to the level you need, guaranteed!
            </p>
            <ul
              class="flex flex-wrap md:flex-nowrap justify-between text-textBlack"
            >
              <li
                class="w-full md:w-4/12 bg-softGrey py-4 px-6 mt-1 md:mt-6 flex items-center"
              >
                <p class="text-base text-center border-textBlack">
                  Reduce​ low-level​ candidates early to save your budget​
                </p>
              </li>
              <li
                class="w-full md:w-4/12 bg-softGrey py-4 px-6 md:mx-2 mt-1 md:mt-6 flex items-center"
              >
                <p class="text-base text-center border-textBlack">
                  Provides pre-qualified pool of English proficient candidates
                </p>
              </li>
              <li
                class="w-full md:w-4/12 bg-softGrey py-4 px-6 md:mr-2 mt-1 md:mt-6 flex items-center"
              >
                <p class="text-base text-center border-textBlack">
                  neo facilitates on-site proctoring when needed
                </p>
              </li>
              <li
                class="w-full md:w-4/12 bg-softGrey py-4 px-6 mt-1 md:mt-6 flex items-center"
              >
                <p class="text-base text-center border-textBlack">
                  Affordable, accurate, adaptive, assessment ensures better
                  outcomes
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!-- <p class="px-4 md:px-0 pt-4 text-center text-secondary">
          If you have the perfect candidate we'll raise their English to the
          level you need, guaranteed! ​
        </p> -->
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-64"
                src="@/assets/images/icons/10.-HR-LAPTOP.png"
                alt="10.-HR-LAPTOP.png"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Easily conduct English audits with your current staff
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  With neo, you can either create a unique URL for test-takers
                  or bulk-upload your staff into groups by job function or
                  department. We offer a simple way to assign the correct level
                  for each department. neoAssess will take care of the rest,
                  providing all the key information to help you determine if
                  your staff members have the necessary skills to perform the
                  assigned duties of their job function.​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-between"
        >
          <div class="w-full text-textBlack text-left">
            <h1 class="pb-2 text-primary text-center text-2xl font-semibold">
              neoAssess – Complete Online Solution
            </h1>
            <ul class="flex flex-wrap md:flex-nowrap text-textBlack">
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 mt-1 md:mt-0 flex items-center justify-center"
              >
                <div>Ease of monitoring and​ sharing results​​</div>
              </li>
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 mt-1 md:mt-0 md:ml-1 flex items-center justify-center"
              >
                <div>Adaptive, accurate assessment with clear results</div>
              </li>
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 mt-1 md:mt-0 md:mx-1 flex items-center justify-center"
              >
                <div>Reporting aids in upskilling your staff</div>
              </li>
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 mt-1 md:mt-0 md:mr-1 flex items-center justify-center"
              >
                <div>Statement of Results</div>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div
          class="px-4 md:px-0 mt-4 text-sm text-textBlack text-center mx-auto"
        >
          For more information contact us at info@nexgenenglishonline.co​
        </div> -->
      </div>
      <div class="bg-white py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Ask us how we can provide you with guaranteed English learning
              outcomes and no budget overruns
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              If any of your staff needs upskilling in English, they can easily
              transition to the neo+ course. Each CEFR-aligned level takes
              approximately 36 hours to complete. Download
              <a
                class="text-secondary"
                href="https://cms.nexgenenglishonline.co/uploads/files/A1%20Nexgen%20Website%20Media%20Lib/Brochures%20NW/neo%2B%20for%20Business.pdf"
                target="_blank"
                >more information</a
              >
              on neo+ guarantees for business users.
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-72"
                src="@/assets/images/icons/neoCertification.jpeg"
                alt="neoCertification.jpeg"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  CEFR-Aligned Certification​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  Your staff will progress through one or more of the 11 CEFR
                  levels of neo+. Each level requires 36 hours of study if they
                  earn 6000 points per week and participate in a 25-minute 1:1
                  coaching session roughly every 2 weeks. Upon completion, staff
                  members receive an internationally recognized CEFR certificate
                  featuring your logo and their picture, along with a unique
                  identifier searchable at Dyned.com for verification.​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full text-textBlack text-center">
            <ul
              class="flex flex-wrap md:flex-nowrap justify-center text-textBlack"
            >
              <li
                class="w-full md:w-3/12 bg-softGrey py-4 px-6 mt-1 md:mt-6 flex items-center"
              >
                <div>Co-branded​ Certificates available</div>
              </li>
              <li
                class="w-full md:w-3/12 bg-softGrey py-4 px-6 md:mx-2 mt-1 md:mt-6 flex items-center"
              >
                <div>Certificates can be downloaded from the neoDashboard​</div>
              </li>
              <li
                class="w-full md:w-3/12 bg-softGrey py-4 px-6 mt-1 md:mt-6 flex items-center"
              >
                <div>
                  Certificate authenticity can be verified
                  <router-link
                    class="text-secondary"
                    to="/studentcertificateverification"
                    >online</router-link
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <ul class="mb-20 flex justify-center">
      <li
        class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
      >
        Download more info
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/9.NEW-hr-main-final.jpg"),
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/9.-HR-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/9.NEW-hr-main-final.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>